/* eslint-disable */

/**
 * 字典：sass_product_type sass产品类型
 * @type {string}
 */
// 云见
export var SASS_PRODUCT_TYPE_YUNJIAN = 'yunjian';
// 云巡宝(云见子集)
export var SASS_PRODUCT_TYPE_YUNXUNBAO = 'yunxunbao';
// 设备宝(云见子集)
export var SASS_PRODUCT_TYPE_SHEBEIBAO = 'shebeibao';

/**
 * 字典：sys_gzh_menu_type h5菜单类型
 * @type {string}
 */
// 用户端菜单
export var SYSTEM_H5_MENU_TYPE_USER = 'SERVICE_H5';
// 服务端菜单
export var SYSTEM_H5_MENU_TYPE_SERVER = 'CLIENT_H5';
// 监管端菜单
export var SYSTEM_H5_MENU_TYPE_SUPERVISE = 'SUPERVISE_H5';
// 监督端菜单
export var SYSTEM_H5_MENU_TYPE_QRCODE = 'QRCODE_H5';

/**
 * 字典：yes_no 是否
 * @type {number}
 */
// 是
export var BOOL_VALUE_YES = 1;
// 否
export var BOOL_VALUE_NO = 0;

/**
 * 字典：yes_no_str 是否
 * @type {string}
 */
// 是
export var STRING_VALUE_YES = 'yes';
// 否
export var STRING_VALUE_NO = 'no';

/**
 * 字典：is_enable 是否启用
 * @type {number}
 */
// 启用
export var ENABLE_STATUS_ON = 0;
// 禁用
export var ENABLE_STATUS_OFF = 1;

/**
 * enabled
 * @type {string}
 */
export var STRING_ENABLE_STATUS_TRUE = 'true';
export var STRING_ENABLE_STATUS_FALSE = 'false';

/**
 * 字典：user_sex 性别
 * @type {number}
 */
// 男性
export var SEX_VALUE_MALE = 1;
// 女性
export var SEX_VALUE_FEMALE = 0;

/**
 * 字典：allow_forbid 是否允许
 * @type {string}
 */
// 允许
export var ALLOW_FORBID_ALLOW = 'allow';
// 禁止
export var ALLOW_FORBID_FORBID = 'forbid';

/**
 * 字典：work_plan_rule_cycle_unit 周期单位
 * @type {string}
 */
// 日
export var CYCLE_UNIT_DAY = 'day';
// 周
export var CYCLE_UNIT_WEEK = 'week';
// 月
export var CYCLE_UNIT_MONTH = 'month';
// 年
export var CYCLE_UNIT_YEAR = 'year';

/**
 * 字典：base_resource_type_all 巡检资源类型
 * @type {number}
 */
// 区域
export var BASE_RESOURCE_TYPE_AREA = 0;
// 楼栋
export var BASE_RESOURCE_TYPE_BUILDING = 1;
// 楼层
export var BASE_RESOURCE_TYPE_FLOOR = 2;
// 房间
export var BASE_RESOURCE_TYPE_ROOM = 3;
// 出入口/门
export var BASE_RESOURCE_TYPE_PASSAGEWAY = 4;
// 道路
export var BASE_RESOURCE_TYPE_ROAD = 5;
// 湖泊
export var BASE_RESOURCE_TYPE_LAKE = 6;
// 树木
export var BASE_RESOURCE_TYPE_TREE = 7;
// 路灯
export var BASE_RESOURCE_TYPE_STREETLIGHT = 8;
// 场地
export var BASE_RESOURCE_TYPE_FIELD = 9;
// 电梯
export var BASE_RESOURCE_TYPE_ELEVATOR = 10;

/**
 * 字典：resources_qrcode_type 资源二维码类型
 * @type {number}
 */
// 垃圾清理
export var BASE_RESOURCE_QRCODE_TYPE_GARBAGE_CLEAN = 1;
// 安保巡更
export var BASE_RESOURCE_QRCODE_TYPE_SAFETY_INSPECT = 2;
// 楼宇巡查
export var BASE_RESOURCE_QRCODE_TYPE_BUILDING_INSPECT = 3;

/**
 * 字典：work_order_status 工单状态
 * @type {string}
 */
// 待接单
export var WORK_ORDER_STATUS_TODO = 'todo';
// 待处理
export var WORK_ORDER_STATUS_DOING = 'doing';
// 处理中(已开工)
export var WORK_ORDER_STATUS_DONE_START = 'done_start';
// 已处理
export var WORK_ORDER_STATUS_DONE = 'done';
// 已取消
export var WORK_ORDER_STATUS_CANCEL = 'cancel';

/**
 * 字典：work_order_evaluation_status 工单评价状态
 * @type {string}
 * @private
 */
// 待评价
export var ORDER_EVALUATION_STATUS_TODO = 'evaluation_todo';
// 已评价
export var ORDER_EVALUATION_STATUS_DONE = 'evaluation_done';

/**
 * 字典：work_order_type 工单类型
 * @type {string}
 */
// 报修工单
export var WORK_ORDER_TYPE_GUARANTEE = 'guarantee';
// 投诉工单
export var WORK_ORDER_TYPE_COMPLAINT = 'complaint';
// 咨询工单
export var WORK_ORDER_TYPE_CONSULTING = 'consulting';
// 建议工单
export var WORK_ORDER_TYPE_PROPOSAL = 'proposal';
// 表扬工单
export var WORK_ORDER_TYPE_PRAISE = 'praise';
// 异常工单
export var WORK_ORDER_TYPE_EXCEPTION = 'exception';
// 整改工单
export var WORK_ORDER_TYPE_RECTIFICATION = 'rectification';
// 预约工单
export var WORK_ORDER_TYPE_RESERVE = 'reserve';
// 任务工单
export var WORK_ORDER_TYPE_TASK = 'task';
// 设备维修工单
export var WORK_ORDER_TYPE_EQUIPMENT_MAINTENANCE = 'equipment_maintenance';

/**
 * 工单流程编码
 * @type {string}
 */
// 报修工单
export var WORK_ORDER_PROCESS_CODE_GUARANTEE = 'work_order_process_guarantee';
// 投诉工单
export var WORK_ORDER_PROCESS_CODE_COMPLAINT = 'work_order_process_complaint';
// 咨询工单
export var WORK_ORDER_PROCESS_CODE_CONSULTING = 'work_order_process_consulting';
// 建议工单
export var WORK_ORDER_PROCESS_CODE_PROPOSAL = 'work_order_process_proposal';
// 表扬工单
export var WORK_ORDER_PROCESS_CODE_PRAISE = 'work_order_process_praise';
// 异常工单
export var WORK_ORDER_PROCESS_CODE_EXCEPTION = 'work_order_process_exception';
// 整改工单
export var WORK_ORDER_PROCESS_CODE_RECTIFICATION = 'work_order_process_rectification';
// 预约工单
export var WORK_ORDER_PROCESS_CODE_RESERVE = 'work_order_process_reserve';
// 任务工单
export var WORK_ORDER_PROCESS_CODE_TASK = 'work_order_process_task';
// 设备维修工单
export var WORK_ORDER_PROCESS_CODE_EQUIPMENT_MAINTENANCE = 'work_order_process_equipment_maintenance';
// 处罚工单（处罚记录，非工单）
export var WORK_ORDER_PROCESS_CODE_WORK_PUNISH = 'work_punish';

/**
 * 字典：work_order_emergency_level 工单紧急状态
 * @type {string}
 */
// 一般
export var WORK_ORDER_EMERGENCY_LEVEL_GENERALLY = 'generally';
// 紧急
export var WORK_ORDER_EMERGENCY_LEVEL_URGENT = 'urgent';
// 加急
export var WORK_ORDER_EMERGENCY_LEVEL_EXPEDITED = 'expedited';

/**
 * 字典：process_todo_button 流程-待办按钮
 * @type {string}
 */
// 创建
export var PROCESS_TODO_BUTTON_START = 'start';
// 接单
export var PROCESS_TODO_BUTTON_DOING = 'doing';
// 转单
export var PROCESS_TODO_BUTTON_REASSIGN = 'reassign';
// 开工
export var PROCESS_TODO_BUTTON_DONE_START = 'done_start';
// 完成
export var PROCESS_TODO_BUTTON_DONE = 'done';
// 返工
export var PROCESS_TODO_BUTTON_REWORK = 'rework';
// 评价
export var PROCESS_TODO_BUTTON_EVALUATION = 'evaluation';
// 审批
export var PROCESS_TODO_BUTTON_APPROVE = 'approve';
// 取消
export var PROCESS_TODO_BUTTON_CANCEL = 'cancel';

/**
 * 字典：work_temp_patrol_type 临时计划类型
 * @type {string}
 */
export var WORK_TEMP_PLAN_TYPE_PATROL = 'patrol';

/**
 * 字典：work_plan_type 工单计划类型
 * @type {string}
 */
// 作业计划
export var WORK_PLAN_TYPE_WORK = 'work_plan';
// 值守计划
export var WORK_PLAN_TYPE_DUTY = 'duty_plan';
// 设备巡检
export var WORK_PLAN_TYPE_EQUIPMENT_INSPECT = 'equipment_inspect';
// 设备维保
export var WORK_PLAN_TYPE_EQUIPMENT_MAINTENANCE = 'equipment_maintenance';
// 巡查监督
export var WORK_PLAN_TYPE_INSPECT_SUPERVISION = 'inspect_supervision';
// 培训计划
export var WORK_PLAN_TYPE_TRAINING = 'training';

/**
 * 字典：work_plan_second_type 工单计划二级类型
 * @type {string}
 */
// 设备巡检
export var WORK_PLAN_SECOND_TYPE_EQUIPMENT_INSPECT = 'e_equipment_inspect';
// 设备维保
export var WORK_PLAN_SECOND_TYPE_EQUIPMENT_MAINTENANCE = 'm_equipment_maintenance';
// 巡查监督
export var WORK_PLAN_SECOND_TYPE_INSPECT_SUPERVISION = 'i_inspect_supervision';
// 值守任务
export var WORK_PLAN_SECOND_TYPE_DUTY_PLAN = 'work_plan_second_type_duty_plan';
// 临巡任务
export var WORK_PLAN_SECOND_TYPE_TEMP_PLAN = 'work_plan_second_type_temp_plan';

/**
 * 字典：work_plan_image_upload_type 工单计划图片上传类型
 * @type {string}
 */
// 无需上传
export var WORK_PLAN_IMAGE_UPLOAD_TYPE_NO = 'no_need_to_upload';
// 按需上传（默认）
export var WORK_PLAN_IMAGE_UPLOAD_TYPE_DEFAULT = 'upload_on_demand_default';
// 必须上传（仅拍照）
export var WORK_PLAN_IMAGE_UPLOAD_TYPE_PHOTOS = 'must_be_uploaded_photos';
// 必须上传（拍照及相册）
export var WORK_PLAN_IMAGE_UPLOAD_TYPE_PHOTOS_AND_ALBUMS = 'must_be_uploaded_photos_albums';

/**
 * 字典：work_plan_image_upload_lower_limit 工单计划图片上传数量最低限制
 * @type {number}
 */
// 默认0张
export var WORK_PLAN_IMAGE_UPLOAD_LOWER_LIMIT_DEFAULT = 0;
// 至少1张
export var WORK_PLAN_IMAGE_UPLOAD_LOWER_LIMIT_ONE = 1;

/**
 * 字典：work_plan_time_plan_type 工单计划作业周期循环类型
 * @type {string}
 */
// 定时
export var WORK_PLAN_RULES_TIME_PLAN_TYPE_TIMING = 'timing';
// 循环
export var WORK_PLAN_RULES_TIME_PLAN_TYPE_LOOP = 'loop';

/**
 * 字典：work_plan_task_status 工单计划任务状态
 * @type {string}
 */
// 未执行
export var WORK_PLAN_TASK_STATUS_NO_EXECUTED = 'not_executed';
// 执行中
export var WORK_PLAN_TASK_STATUS_IN_EXECUTED = 'in_execution';
// 已执行
export var WORK_PLAN_TASK_STATUS_HAS_BEEN_EXECUTED = 'has_been_executed';
// 已取消
export var WORK_PLAN_TASK_STATUS_CANCELLED = 'cancelled';

/**
 * 字典：work_plan_task_detail_punch_status 任务打卡状态
 * @type {string}
 */
// 已打卡
export var WORK_PLAN_TASK_PUNCH_STATUS_PUNCHED = 'punched_in';
// 未打卡
export var WORK_PLAN_TASK_PUNCH_STATUS__NOT_PUNCHED = 'not_punched_in';

/**
 * 字典：work_plan_task_opt_code 操作编码
 * @type {string}
 */
// 接班
export var WORK_PLAN_TASK_OPT_CODE_TAKE_OVER = 'take_over';
// 交班
export var WORK_PLAN_TASK_OPT_CODE_SHIFT = 'shift';
// 打卡
export var WORK_PLAN_TASK_OPT_CODE_CLOCK = 'clock_in';

/**
 * 字典：work_plan_task_alert_status 计划任务预警级别
 * @type {string}
 */
// 无
export var WORK_PLAN_TASK_ALERT_LEVEL_NONE = 'none';
// 蓝灯
export var WORK_PLAN_TASK_ALERT_LEVEL_GREEN = 'green';
// 黄灯
export var WORK_PLAN_TASK_ALERT_LEVEL_YELLOW = 'yellow';
// 红灯
export var WORK_PLAN_TASK_ALERT_LEVEL_RED = 'red';

/**
 * 字典：work_plan_content_option 作业内容选项
 * @type {string}
 */
// 手动输入
export var WORK_PLAN_CONTENT_OPTION_MANUAL_INPUT = 'manual_input';

/**
 * 字典：work_plan_operation_mode 作业方式
 * @type {string}
 */
// 扫码执行
export var WORK_PLAN_OPERATION_MODE_SCAN = 'scan_code';
// 直接执行
export var WORK_PLAN_OPERATION_MODE_DIRECT = 'direct';

/**
 * 字典：audit_status 审核状态
 * @type {string}
 */
// 未审核
export var audit_status_NOT_AUDITED = 'not_audited';
// 已审核
export var audit_status_AUDITED = 'audited';

/**
 * 字典：investigation_time_type 考评时间类型
 * @type {number}
 */
// 年度
export var INVESTIGATION_TIME_TYPE_YEAR = 1;
// 季度
export var INVESTIGATION_TIME_TYPE_QUARTER = 2;
// 月度
export var INVESTIGATION_TIME_TYPE_MONTH = 3;

/**
 * 字典：task_state 考评任务状态
 * @type {number}
 */
// 未发布
export var EVALUATE_TASK_STATE_UNPUBLISHED = 1;
// 已发布
export var EVALUATE_TASK_STATE_PUBLISHED = 2;
// 已停止
export var EVALUATE_TASK_STATE_STOPPED = 3;
// 已完成
export var EVALUATE_TASK_STATE_FINISHED = 4;

/**
 * 字典：implement_state 考评执行状态
 * @type {number}
 */
// 未完成
export var EVALUATE_IMPLEMENT_STATE_UNFINISHED = 1;
// 已完成
export var EVALUATE_IMPLEMENT_STATE_FINISHED = 2;

/**
 * 字典：archives_level 档案保密级别
 * @type {number}
 */
// 公开
export var ARCHIVES_LEVEL_NORMAL = 1;
// 保密
export var ARCHIVES_LEVEL_SECRET = 2;
// 绝密
export var ARCHIVES_LEVEL_TOP_SECRET = 3;

/**
 * 问卷模板题目类型
 */
//选择
export var OSS_QUESTIONNAIRE_SUBJECT_TYPE_CHOOSE = 1;
//填空
export var OSS_QUESTIONNAIRE_SUBJECT_TYPE_FILL_IN_THE_BLANK = 2;
//分页说明
export var OSS_QUESTIONNAIRE_SUBJECT_TYPE_PAGING_INSTRUCTIONS = 3;
//评分
export var OSS_QUESTIONNAIRE_SUBJECT_TYPE_SCORE = 4;

/**
 * 字典：oss_device_ledger_status 设备台账状态
 * @type {number}
 */
// 正常
export var OSS_DEVICE_LEDGER_STATUS_NORMAL = 0;
// 停机
export var OSS_DEVICE_LEDGER_STATUS_STOPED = 1;
// 故障
export var OSS_DEVICE_LEDGER_STATUS_ERROR = 2;
// 维保
export var OSS_DEVICE_LEDGER_STATUS_MAINTENANCE = 3;
// 维修
export var OSS_DEVICE_LEDGER_STATUS_REPAIRED = 4;

/**
 * 字典：stock_warehouse_bill_type 出入库类型
 * @type {string}
 */
// 入库
export var MATERIAL_CHECKOUT_TYPE_IMPORT = 'entry';
// 出库
export var MATERIAL_CHECKOUT_TYPE_EXPORT = 'export';

/**
 * 入库状态
 *
 * @type {string}
 */
// 入库完成
export var MATERIAL_STORAGE_STATUS_FINISHED = 'finished';
// 已作废
export var MATERIAL_STORAGE_STATUS_CANCELED = 'canceled';

/**
 * 字典：stock_warehouse_entry_bill_warehouse_entry_status 入库状态
 * @type {string}
 */
// 入库完成
export var MATERIAL_IMPORT_STATUS_COMPLETED = 'completed';
// 已作废
export var MATERIAL_IMPORT_STATUS_ABOLISHED = 'abolished';

/**
 * 字典：stock_warehouse_entry_bill_type 入库类型
 * @type {string}
 */
// 采购入库
export var MATERIAL_IMPORT_TYPE_PURCHASE = 'purchase';
// 期初入库
export var MATERIAL_IMPORT_TYPE_BEGINNING = 'beginning';
// 盘盈入库
export var MATERIAL_IMPORT_TYPE_SURPLUS = 'surplus';
// 其他入库
export var MATERIAL_IMPORT_TYPE_OTHER = 'other';

/**
 * 字典：stock_warehouse_export_bill_type 出库类型
 * @type {string}
 */
// 正常出库
export var MATERIAL_EXPORT_TYPE_NORMAL = 'normal';
// 盘亏出库
export var MATERIAL_EXPORT_TYPE_LOSS = 'loss';
// 损耗出库
export var MATERIAL_EXPORT_TYPE_CONSUME = 'consume';
// 工单领料出库
export var MATERIAL_EXPORT_TYPE_WORK_ORDER_PICKING = 'work_order_picking';

/**
 * 字典：stock_warehouse_export_bill_warehouse_export_status 出库状态
 * @type {string}
 */
// 出库完成
export var MATERIAL_EXPORT_STATUS_COMPLETED = 'completed';
// 已作废
export var MATERIAL_EXPORT_STATUS_ABOLISHED = 'abolished';

/**
 * 字典：stock_warehouse_bill_checkout_status 结账状态
 * @type {string}
 */
// 未结账
export var MATERIAL_CHECKOUT_STATUS_UNPAID = 'unpaid';
// 已结账
export var MATERIAL_CHECKOUT_STATUS_CHECKED = 'checked_out';

/**
 * 字典：resource_add_type 资产创建模式
 * @type {string}
 */
// 单独创建
export var RESOURCE_ADD_TYPE_ONE = 'add_one';
// 批量创建
export var RESOURCE_ADD_TYPE_BATCH = 'add_batch';

/**
 * 预约类型
 * @type {string}
 */
// 访客预约
export var REGISTER_TYPE_RESERVE_VISITOR = 'reserve_visitor';

/**
 * H5消息推送计划类型 wechat_gzh_push_config_type
 * @type {string}
 */
// 云管/云监日报
export var H5_PUSH_CONFIG_TYPE_SERVICE_SUPERVISE_DAILY = 'service_supervise_daily';

/**
 * 出勤类型 oss_attendance_shift_setting_type
 * @type {string}
 */
// 出勤
export var ATTENDANCE_CLASSES_SETTING_TYPE_ATTENDANCE = 'attendance';
// 休息
export var ATTENDANCE_CLASSES_SETTING_TYPE_REST = 'rest';

/**
 * 出勤时间类型 oss_attendance_shift_setting_time_type
 * @type {string}
 */
// 昨天
export var ATTENDANCE_CLASSES_SETTING_TIME_TYPE_YESTERDAY = 'yesterday';
// 今天
export var ATTENDANCE_CLASSES_SETTING_TIME_TYPE_TODAY = 'today';
// 明天
export var ATTENDANCE_CLASSES_SETTING_TIME_TYPE_TOMORROW = 'tomorrow';

/**
 * 考勤打卡类型 oss_attendance_record_type
 * @type {string}
 */
// 上班
export var ATTENDANCE_RECORD_TYPE_WORK = 'work';
// 下班
export var ATTENDANCE_RECORD_TYPE__OFF_WORK = 'off_work';

/**
 * 考勤记录类型 attendance_recored_type
 * @type {string}
 */
// 正常考勤
export var ATTENDANCE_CALENDAR_TYPE_NORMAL = 'normal';
// 异常
export var ATTENDANCE_CALENDAR_TYPE_ABNORMAL = 'abnormal';
// 迟到
export var ATTENDANCE_CALENDAR_TYPE_LATE = 'be_late';
// 严迟
export var ATTENDANCE_CALENDAR_TYPE_LATE_SERIOUS = 'seriously_late';
// 旷迟
export var ATTENDANCE_CALENDAR_TYPE_LATE_ABSENTEEISM = 'absenteeism_late';
// 早退
export var ATTENDANCE_CALENDAR_TYPE_EARLY = 'leave_early';
// 旷工
export var ATTENDANCE_CALENDAR_TYPE_ABS = 'absenteeism';
// 休息
export var ATTENDANCE_CALENDAR_TYPE_REST = 'rest';
// 无
export var ATTENDANCE_CALENDAR_TYPE_NONE = 'null';
// 请假
export var ATTENDANCE_CALENDAR_TYPE_ASK_FOR_LEAVE = 'ask_for_leave';
// 补卡
export var ATTENDANCE_CALENDAR_TYPE_REPLACEMENT = 'replacement_card';
// 外出
export var ATTENDANCE_CALENDAR_TYPE_GO_OUT = 'go_out';
// 出差
export var ATTENDANCE_CALENDAR_TYPE_BUSINESS_TRIP = 'on_business_trip';
// 加班
export var ATTENDANCE_CALENDAR_TYPE_WORK_TIME = 'work_overtime';
export var COVER_SHIFT = 'cover_shift';

/**
 * 周
 * @type {number}
 */
export var WEEK_DAY_1 = 1;
export var WEEK_DAY_2 = 2;
export var WEEK_DAY_3 = 3;
export var WEEK_DAY_4 = 4;
export var WEEK_DAY_5 = 5;
export var WEEK_DAY_6 = 6;
export var WEEK_DAY_7 = 7;

/**
 * 通讯录权限 sys_user_address_book_permission
 * @type {string}
 */
// 全部
export var USER_CONTACTS_DEPT_AUTH_ALL = 'all';
// 部分
export var USER_CONTACTS_DEPT_AUTH_PART = 'part';
//无
export var USER_CONTACTS_DEPT_AUTH_NONE = 'none';

/**
 * 职工状态 sys_user_employee_status
 * @type {string}
 */
// 试用
export var USER_EMPLOYEE_STATUS_TRY_OUT = 'try_out';
// 正式
export var USER_EMPLOYEE_STATUS_FORMAL = 'formal';
// 离职
export var USER_EMPLOYEE_STATUS_RESIGN = 'resign';

/**
 * 考勤打卡范围 attendance_scope_type
 * @type {number}
 */
export var ATTENDANCE_SCOPE_TYPE_100 = 100;
export var ATTENDANCE_SCOPE_TYPE_500 = 500;

/**
 * 培训-试卷-题目-选项类型 train_test_papery_question_option_type
 * @type {string}
 */
// 单选
export var TRAIN_COURSE_PAPER_QUESTION_OPTION_TYPE_SINGLE = 'single_choice';
// 多选
export var TRAIN_COURSE_PAPER_QUESTION_OPTION_TYPE_MULTIPLE = 'multiple_choice';

/**
 * 匿名用户入职状态 sys_onboarding_user_status
 * @type {string}
 */
// 待入职
export var ANONYMOUS_USER_STATUS_TO_BE_EMPLOYED = 'to_be_employed';
// 入职中
export var ANONYMOUS_USER_STATUS_BOARDING = 'onboarding';
// 已入职
export var ANONYMOUS_USER_STATUS_EMPLOYED = 'already_employed';